import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Input,
  FormGroup,
  Label
} from "reactstrap";
import { DELETECONSOLE } from "../../ConfigurationGraphQL";
import { useMutation } from "@apollo/react-hooks";

export const DeleteRecordsModal = ({
  deleteRecord,
  toastMessgage,
  gridApi,
  setActionModal
}) => {
  const [deleteConsole] = useMutation(DELETECONSOLE);
  const [isLoading, setUploading] = useState(false);
  const [selectedRecCount] = useState(() => {
    const selectedNodes = gridApi.getSelectedNodes();
    return selectedNodes.length;
  });
  const [count, setCount] = useState(0);
  const [isValid, setIsValid] = useState(false);

  const handleChange = e => {
    const selectedNodes = gridApi.getSelectedNodes();
    const val = parseInt(e.target.value);
    setCount(val);
    if (selectedNodes.length === val) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const deleteRow = async () => {
    try {
      setUploading(true);
      const selectedNodes = gridApi.getSelectedNodes();
      let idList = selectedNodes.map(node => parseInt(node.data.id));
      await deleteConsole({ variables: { id: idList } });
      deleteRecord(idList);
      setActionModal({});
      toastMessgage("success", "Done");
      setUploading(false);
    } catch (e) {
      setUploading(false);
      toastMessgage("error", e.message);
    }
  };
  return (
    <Modal
      isOpen={true}
      centered={true}
      zIndex={999999999}
      tabIndex="4"
      role="dialog"
    >
      <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">
        Deleting {selectedRecCount} records
      </ModalHeader>
      <ModalBody className="p-4">
        <p className="text-center">
          {" "}
          Do you want to delete <strong>{selectedRecCount}</strong> records ?{" "}
        </p>

        <div className="bg-light border rounded p-4">
          <FormGroup className="m-0">
            <Row className="align-items-center">
              <Col xs="auto">
                <Label
                  for="count"
                  className="font-italic font-weight-500 m-0 text-primary"
                >
                  Enter selected record count here:
                </Label>
              </Col>
              <Col>
                <Input
                  id="count"
                  placeholder="insert count"
                  type="text"
                  className="border-dashed rounded-0"
                  value={count}
                  onChange={handleChange}
                />
              </Col>
            </Row>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row className="justify-content-center">
          <Col xs="7" className="pr-0">
            {isLoading ? (
              <Button color="primary" className="h-100 postion-relative" block>
                <Spinner size="sm" className="text-white" />
              </Button>
            ) : (
              <Button
                color="primary"
                block
                onClick={deleteRow}
                disabled={!isValid}
              >
                Confirm
              </Button>
            )}
          </Col>
          <Col>
            <Button color="secondary" block onClick={() => setActionModal({})}>
              Cancel
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};
export default DeleteRecordsModal;
