import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const RenderMessageBox = (props) => {
  const {
    isOpen,
    btnOne,
    message,
    handleClick
  } = props;
 
  return (  
      <Modal isOpen={isOpen}>
        <ModalBody>
          {message}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={()=>handleClick(false)}>{btnOne}</Button>
        </ModalFooter>
      </Modal>  
  );
}

export default RenderMessageBox;