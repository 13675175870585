/* eslint-disable react/prop-types */
import React, { memo } from "react";
// import { Col } from 'reactstrap'
import StatusCard from "./statusCard";
import { AutoSizer, Grid } from "react-virtualized";

const COLUMNWIDTH = 155;
const ROWHEIGHT = 160;

const ItemsList = ({ consData, updateItem }) => {
  if (!consData || !consData.length) return null;
  return (
    <AutoSizer>
      {({ height, width }) => {
        const containerWidth = document.querySelector(".status-alignment")
          .offsetWidth;
        let columnCount = 6;
        if (containerWidth) {
          columnCount = parseInt(containerWidth / 155);
        }
        const itemsPerRow = Math.floor(width / ROWHEIGHT);
        const rowCount = Math.ceil(consData.length / itemsPerRow);
        height = 545;
        if (document.querySelector(".status-alignment")) {
          height = document.querySelector(".status-alignment").offsetHeight - 5;
        }
        return (
          <Grid
            cellRenderer={({ key, style, rowIndex, columnIndex }) => {
              const itemIndex =
                rowIndex * (columnCount - 1) + (rowIndex + columnIndex);
              const item = consData[itemIndex];
              if (!item) return "";
              return (
                <div key={key} style={style}>
                  <StatusCard
                    key={item.id}
                    item={item}
                    updateItem={updateItem}
                  />
                </div>
              );
            }}
            overscanRowCount={30}
            columnWidth={COLUMNWIDTH}
            columnCount={columnCount}
            height={height}
            rowHeight={ROWHEIGHT}
            rowCount={rowCount}
            width={width}
          />
        );
      }}
    </AutoSizer>
  );
};

export default memo(ItemsList);
