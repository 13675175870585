/* eslint-disable react/display-name */

import React, { memo, useRef } from "react";
import { debounce } from "throttle-debounce";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

// var modes = {
//   tree: 'tree',
//   view: 'view',
//   form: 'form',
//   code: 'code',
//   text: 'text'
// };

export const JsonEditor = memo(({ setSettings, json, setJson }) => {
  const checkIfJSONIsValid = debounce(500, json => {
    setSettings(json);
  });

  const handleChange = newJson => {
    setJson(newJson);
    checkIfJSONIsValid(newJson);
  };

  const innerRef = useRef(null);

  return (
    <div className="modal-json-container">
      <Editor
        value={json}
        onChange={handleChange}
        history
        ref={e => (innerRef.current = e)}
        htmlElementProps={{
          style: {
            height: 750
          }
        }}
        mode="text"
      />
    </div>
  );
});
