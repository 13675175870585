import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const RenderDialogBox = (props) => {
  const {
    handleDialogBox,
    isOpen,
    btnOne,
    btnTwo,
    message,
    handleClickonYes
  } = props;


  return (
    <Modal isOpen={isOpen}>
      <ModalBody>
        {message}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="sm" onClick={() => handleClickonYes()}>{btnOne}</Button>{' '}
        <Button color="secondary" size="sm" onClick={() => handleDialogBox(false)}>{btnTwo}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default RenderDialogBox;