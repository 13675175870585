import React from "react";
import { Collapse, Nav, NavItem, NavLink, Button } from "reactstrap";
import Status from "../../assets/icons/status.svg";
import Configuration from "../../assets/icons/configuration.svg";
import Settings from "../../assets/icons/settings.svg";
import Help from "../../assets/icons/help.svg";
import Logout from "../../assets/icons/logout.svg";
import { useHistory, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clearFilterInStore } from "../../pages/Configuration/ConfigurationActions";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { removeCaptchaKey } from "../../pages/Settings/SettingsActions";
import { client } from "../../reducers/config";
import { useSubscription } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import { toggleLeftSideBar } from "../HamBurgerMenu/HamBurgerMenuAction";
import { useLocation } from "react-router-dom";

export const USERINFO_SUBSCRIBE = gql`
  subscription userInfo($userId: Int!) {
    userInfo(userId: $userId)
  }
`;

const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const { isOpenLeftSideBar } = useSelector(
    state => state.toggleLeftSideBarReducer
  );

  const isActiveSidebar = isOpenLeftSideBar ? "sidebar-active" : "";

  let user = JSON.parse(localStorage.getItem("consolebot.user"));

  const { data: userData } = useSubscription(USERINFO_SUBSCRIBE, {
    variables: { userId: parseInt(user.id) }
  });

  const handleClickOnLogOut = async () => {
    await dispatch(removeCaptchaKey());
    await dispatch(clearFilterInStore());
    history.push(`/login`);
    localStorage.clear();
    client.resetStore();
  };

  if (!user) {
    handleClickOnLogOut();
  }

  if (userData) {
    user = { ...userData.userInfo };
    delete user.password;
    localStorage.setItem("consolebot.user", JSON.stringify(user));
  }

  const isConfiguration = pathname === "/configuration";

  return (
    <>
      <div
        className={`sidebar-menu ${isActiveSidebar}${
          isConfiguration ? " configuration-view" : ""
        }`}
      >
        <Button
          color="link"
          className="text-primary text-left w-100 p-3"
          onClick={() => dispatch(toggleLeftSideBar(!isOpenLeftSideBar))}
        >
          {isOpenLeftSideBar ? (
            <span className="d-flex align-items-center justify-content-between">
              <span className="font-weight-bolder"> {user.balance} </span>{" "}
              <FontAwesomeIcon icon={faTimes} className="fa-fw" />
            </span>
          ) : (
            <FontAwesomeIcon icon={faBars} className="fa-fw" />
          )}
        </Button>

        <Collapse isOpen={isOpenLeftSideBar} className="open-sidebar">
          <Nav vertical className="sidebar-nav">
            <NavItem>
              <Link
                to="/overview"
                className={
                  pathname === "/overview" ? "nav-link active" : "nav-link"
                }
              >
                <img src={Status} className="nav-icon" alt="" />
                Overview
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/configuration"
                className={
                  pathname === "/configuration" ? "nav-link active" : "nav-link"
                }
              >
                <img src={Configuration} className="nav-icon" alt="" />
                Configuration
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/settings"
                className={
                  pathname === "/settings" ? "nav-link active" : "nav-link"
                }
              >
                <img src={Settings} className="nav-icon" alt="" />
                Settings
              </Link>

              {/* <NavLink
                onClick={toggleMenu}
                className="d-flex justify-content-between align-items-center"
              >
                <span>
                  <img src={Settings} className="nav-icon" alt="" />
                  Settings
                </span>
                {isOpen ? <GoChevronUp /> : <GoChevronDown />}
              </NavLink>
              <Collapse isOpen={isOpen}>
                <Link
                  to="/global"
                  className={
                    pathname === "/global" ? "nav-link active" : "nav-link"
                  }
                >
                  <AiOutlineGlobal className="nav-icon ml-3" size="1em" />
                  Global
                </Link>

                <Link
                  to="/config"
                  className={
                    pathname === "/config" ? "nav-link active" : "nav-link"
                  }
                >
                  <DiJavascript className="nav-icon ml-3" size="1em" />
                  Config
                </Link>
              </Collapse> */}
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href={
                  "//notion.so/consolebotmanual/A-console-bot-com-user-manual-9ea4315375d84233b9cc47cf3bb1d265"
                }
                target="_blank"
              >
                <img src={Help} className="nav-icon" alt="" />
                Manual
              </NavLink>
            </NavItem>

            {/* <hr className="mb-0 mt-auto" /> */}
            <NavItem className="footer-item">
              <NavLink onClick={() => handleClickOnLogOut()}>
                <img src={Logout} className="nav-icon" alt="" />
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </>
  );
};

export default Header;
