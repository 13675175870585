import React from "react";
import Global from "./components/GlobalSettings";
import SettingsList from "./components/List";
import { Container, Row, Col } from "reactstrap";
const Settings = () => {
  return (
    <main
      role="main"
      className="content-block__width ml-auto px-0 global-wrapper"
    >
      <div className="page-details-stripe">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h1 className="stripe-heading m-0 font-weight-600">Settings</h1>
            </Col>
            <Col xs="auto">
              <small className="text-muted"></small>
            </Col>
          </Row>
        </Container>
      </div>
      <Global />
      <SettingsList />
    </main>
  );
};

export default Settings;
