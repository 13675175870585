export const steamReg = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { steamRegTask }
  } = props;

  const selectedNodes = gridApi.getSelectedNodes();
  let regList = [];
  selectedNodes.forEach(node => {
    regList.push(parseInt(node.data.id));
  });

  try {
    if (regList.length > 0) {
      const result = await steamRegTask({
        variables: { steamList: regList }
      });
      const response = result?.data?.steamRegTask ?? null;
      if (response) {
        toastMessgage(response.status, response.message);
      } else {
        toastMessgage("error", "No response");
      }
    } else {
      toastMessgage("info", "There is no record for Registration");
    }
  } catch (e) {
    toastMessgage("error", e.message);
  }
};

export default steamReg;
