import React from "react";
import DeleteRecordsModal from "./DeleteRecordsModal";
import { LicenseDialog, LicenseErrorDialog } from "./LicenseModals";
import UnpairingModal from "./UnpairingModal";
import UploadCSV from "../../../../common/UploadCSV";

export const ACTION_MODALS = {
  UNPAIRING: "unpairing",
  LICENSE: "license",
  LICENSE_ERROR: "license_error",
  DELETE: "delete",
  UPLOAD_CSV: "uploadCsv"
};

const ActionModals = ({ actionModal, actionModalProps, fetchRecord }) => {
  if (!actionModal) return null;
  if (actionModal[ACTION_MODALS.UNPAIRING]) {
    return <UnpairingModal {...actionModalProps} />;
  } else if (actionModal[ACTION_MODALS.LICENSE]) {
    return <LicenseDialog {...actionModalProps} />;
  } else if (actionModal[ACTION_MODALS.LICENSE_ERROR]) {
    return <LicenseErrorDialog {...actionModalProps} />;
  } else if (actionModal[ACTION_MODALS.DELETE]) {
    return <DeleteRecordsModal {...actionModalProps} />;
  } else if (actionModal[ACTION_MODALS.UPLOAD_CSV]) {
    return (
      <UploadCSV
        fetchRecord={fetchRecord}
        setActionModal={actionModalProps.setActionModal}
      />
    );
  }
  return null;
};

export default ActionModals;
