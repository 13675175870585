import React, { Component } from "react";
// import { Input } from "reactstrap";
import moment from "moment";
import Datetime from "react-datetime";

export default class DatePickerRender extends Component {
  state = {
    date: this.props.value
      ? moment.utc(this.props.value).local().format("YYYY-MM-DDThh:mm")
      : new Date()
  };
  getValue() {
    return this.state.date;
  }
  dateChange = async date => {
    await this.setState({ date });
  };
  render() {
    return (
      <div className="date-picker">
        <Datetime value={this.state.date} onChange={this.dateChange} />
      </div>
    );
  }
}
