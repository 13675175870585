import React, { useMemo, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useQuery } from "@apollo/react-hooks";
import { DUPLICATE_SCRIPT, GETSCRIPTSETTINGS } from "../../SettingsGraphQL";
// import EditModalLegacy from "../EditModal";
import RenderDialogBox from "../../../../common/RenderDialogBox";
import { DELETE_SCRIPT_SETTING } from "../../SettingsGraphQL";
import { useMutation } from "react-apollo";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import { SettingsListItem } from "./SettingsListItem";
import { SettingsEditModal } from "../EditModal/SettingsEditModal";

const warnings = {
  delete: "Are sure you want to remove setting?",
  copy: "Copy config?"
};

const SettingsList = () => {
  const { data, loading, error, refetch } = useQuery(GETSCRIPTSETTINGS);
  const [deleteScript] = useMutation(DELETE_SCRIPT_SETTING);
  const [copyScript] = useMutation(DUPLICATE_SCRIPT);
  // const [isOpenForm, handleForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [isOpenWarningBox, setWarningBox] = useState("");
  const [editedJson, setEditedJson] = useState("");

  const settingList = useMemo(
    () =>
      data?.getScriptSettings?.sort(
        (a, b) => parseInt(a.id) - parseInt(b.id)
      ) || [],
    [data]
  );

  const handleClickonYes = async () => {
    const { id } = selectedItem;
    if (isOpenWarningBox === "delete") {
      try {
        await deleteScript({ variables: { id: parseInt(id) } });
        toastMessgage("success", "Delete script!");
        refetch();
        setWarningBox("");
      } catch (e) {
        toastMessgage("error", e.message);
      }
    } else if (isOpenWarningBox === "copy") {
      try {
        const copyResult = await copyScript({
          variables: { id: parseInt(id) }
        });
        const resp = copyResult?.data?.duplicateScript;

        toastMessgage(resp?.status || "error", resp?.message || "");
        refetch();
        setWarningBox("");
      } catch (e) {
        toastMessgage("error", e.message);
      }
    }
  };

  const addNew = () => {
    setSelectedItem(undefined);
    // handleForm(true);
    setEditedJson({});
  };

  // const editScriptItem = item => {
  //   setSelectedItem(item);
  //   handleForm(true);
  // };

  const copyScriptItem = item => {
    setSelectedItem(item);
    setWarningBox("copy");
  };

  const renderScriptList = () => {
    // const settingList = data.getScriptSettings;
    if (settingList.length === 0) {
      return " There is no script setting available";
    }
    return settingList.map((item, i) => {
      return (
        <SettingsListItem
          item={item}
          key={item.id + i}
          setWarningBox={setWarningBox}
          setSelectedItem={setSelectedItem}
          // editScriptItem={editScriptItem}
          copyScriptItem={copyScriptItem}
          setEditedJson={setEditedJson}
        />
      );
    });
  };

  const toastMessgage = (type, msg) => {
    if (type === "success") {
      ToastsStore.success(msg);
    } else {
      ToastsStore.error(msg);
    }
  };

  const renderList = () => {
    if (loading) return <div>{`Loading...`}</div>;
    if (error) return <div>{`Something went wrong`}</div>;
    return (
      <>
        <div className="content-wrapper">
          <div className="scriptContentWrapper">{renderScriptList()}</div>
          {/* <EditModalLegacy
            isOpen={isOpenForm}
            closeFormModel={handleForm}
            refetch={refetch}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            toastMessgage={toastMessgage}
          /> */}

          <RenderDialogBox
            isOpen={!!isOpenWarningBox}
            handleDialogBox={() => setWarningBox("")}
            btnOne={"Yes"}
            btnTwo={"No"}
            message={warnings[isOpenWarningBox] || ""}
            handleClickonYes={handleClickonYes}
          />
        </div>
        <ToastsContainer
          position={ToastsContainerPosition.TOP_CENTER}
          store={ToastsStore}
        />
      </>
    );
  };

  return (
    <>
      <div className="page-details-substripe">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="stripe-heading m-0 font-weight-500">Config</h2>
            </Col>
            <Col xs="auto">
              <small className="text-muted"></small>
            </Col>
            <Button
              size="sm"
              className={"btn-add-setting"}
              color="primary"
              onClick={addNew}
            >
              Add config
            </Button>
          </Row>
        </Container>
        {editedJson ? (
          <SettingsEditModal
            editedJson={editedJson}
            setEditedJson={setEditedJson}
            toastMessgage={toastMessgage}
            refetch={refetch}
          />
        ) : null}
      </div>
      {renderList()}
    </>
  );
};

export default SettingsList;
