export const banCheckButton = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { banCheck }
  } = props;

  const user = JSON.parse(localStorage.getItem("consolebot.user"));
  const selectedNodes = gridApi.getSelectedNodes();
  let regList = {};
  selectedNodes.forEach(node => {
    let isFieldMissing = false;
    if (!node.data.ea_email) {
      isFieldMissing = true;
    }
    if (!node.data.ea_imapusername) {
      isFieldMissing = true;
    }
    if (!node.data.ea_imappassword) {
      isFieldMissing = true;
    }
    if (!node.data.ea_imaphost) {
      isFieldMissing = true;
    }
    if (!node.data.ea_imapport) {
      isFieldMissing = true;
    }
    if (isFieldMissing) {
      //do nothing
    } else {
      if (!regList[user.email]) regList[user.email] = {};

      let imap = `${node.data.ea_imapusername}:${node.data.ea_imappassword}@${node.data.ea_imaphost}:${node.data.ea_imapport}`;

      if (!regList[user.email][imap]) regList[user.email][imap] = [];

      regList[user.email][imap].push({
        [node.data.ea_email]: node.data.imap_proxy
      });
    }
  });

  try {
    if (Object.keys(regList).length > 0) {
      await banCheck({ variables: { BANRef: regList } });
      toastMessgage("success", "Done");
    } else {
      toastMessgage("info", "There is no record for Ban check");
    }
  } catch (e) {
    toastMessgage("error", e.message);
  }
};
export default banCheckButton;
