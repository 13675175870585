import { gridStateStorage } from "../../index";

function StateResetButton() {}

StateResetButton.prototype.init = function (params) {
  this.params = params;

  this.eGui = document.createElement("div");

  var label = document.createElement("div");
  label.innerText = "Reset state";
  label.style.textDecoration = "underline";
  label.className = "grid-reset-state-button";
  label.addEventListener("click", async function () {
    const user = JSON.parse(localStorage.getItem("consolebot.user"));
    const { id } = user || {};
    if (id) {
      await gridStateStorage.removeItem(id);
      window.location.reload();
    }
  });
  this.eGui.appendChild(label);
};

StateResetButton.prototype.getGui = function () {
  return this.eGui;
};

export default StateResetButton;
