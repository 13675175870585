/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  Label
} from "reactstrap";
import { JsonEditor } from "./JsonEditor";
import { useMutation } from "@apollo/react-hooks";
import {
  ADD_SCRIPT_SETTING,
  UPDATE_SCRIPT_SETTING
} from "../../SettingsGraphQL";

export const SettingsEditModal = ({
  editedJson,
  setEditedJson,
  toastMessgage,
  refetch
}) => {
  const customerId = parseInt(localStorage.getItem("consolebot.userId"));
  const [addScript] = useMutation(ADD_SCRIPT_SETTING);
  const [updateScript] = useMutation(UPDATE_SCRIPT_SETTING);
  const [configName, setConfigName] = useState(() => editedJson.name || "");
  const [json, setJson] = useState(
    () =>
      (editedJson.json_settings && JSON.parse(editedJson.json_settings)) || []
  );

  const [validSettings, setValidSettings] = useState(() =>
    editedJson.json_settings ? JSON.parse(editedJson.json_settings) : []
  );

  const onSave = async () => {
    try {
      if (validSettings) {
        const variables = {
          input: {
            ...editedJson,
            json_settings: JSON.stringify(validSettings),
            name: configName,
            customer_id: customerId,
            discord_chat_id:
              json.discord_chat_id || editedJson?.discord_chat_id || "0",
            port: json.discord_chat_id || editedJson?.port || null
          }
        };
        if (editedJson.id) {
          variables.id = parseInt(editedJson.id);
          await updateScript({ variables });
        } else {
          await addScript({ variables });
        }
        refetch();
        toastMessgage("success", "Update script setting!");
        setEditedJson(null);
      } else {
        toastMessgage("success", "WE NEED TO IMPLEMENT ADDING");
      }
    } catch (e) {
      toastMessgage("error", e.message);
    }
  };

  const setSettings = useCallback(setValidSettings, []);
  const setJsonCallback = useCallback(setJson, []);

  return (
    <div>
      <Modal isOpen className="config-modal-form2">
        <div className="ml-4 mt-2 d-flex align-items-center">
          <Label className="mb-1 mr-3" for="name">
            Name:
          </Label>
          <Input
            style={{ maxWidth: 200 }}
            type="text"
            onChange={e => setConfigName(e.target.value)}
            value={configName}
            id="name"
          />
        </div>
        <ModalBody className="number">
          <JsonEditor
            setSettings={setSettings}
            json={json}
            setJson={setJsonCallback}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setEditedJson(null)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => onSave(null)}
            color="primary"
            disabled={!validSettings || !editedJson || !configName}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
