import React from "react";
import { render } from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "./styles/style.css";
import App from "./App";
import { ApolloProvider } from "react-apollo";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastProvider } from "react-toast-notifications";
import store, { persistingStore } from "./reducers/config";
import { client } from "./reducers/config";
import Loader from "./common/Loader";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";

const WebApp = () => (
  <ApolloProvider client={client}>
    <Provider store={store}>
      <ToastProvider autoDismiss autoDismissTimeout={3000}>
        <PersistGate
          loading={localStorage.getItem("consolebot.token") ? null : <Loader />}
          persistor={persistingStore}
        >
          <App />
        </PersistGate>
      </ToastProvider>
    </Provider>
  </ApolloProvider>
);

render(<WebApp />, document.getElementById("root"));
