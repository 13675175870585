import React from "react";
import {
  Row,
  Col,
  Card,
  CardImg,
  CardText,
  CardBody,
  Label,
  Input
} from "reactstrap";
import GameThumbnail from "../../../../../assets/images/game-thumbnail-blank.png";

// eslint-disable-next-line no-undef
let rcServerUrl = process.env.REACT_APP_rcServerUrl;

const StatusCard = props => {
  let { item, updateItem } = props;

  const getBackgroundColor = () => {
    if (item.isChecked) {
      return "#13A3D8";
    }
    if (
      item.status &&
      typeof item.status === "string" &&
      item.status.toLowerCase() === "error"
    ) {
      return "#f26578"; // red
    }

    if (item.times_tamp === "" || item.times_tamp === null) {
      return "#fff";
    }
    if (item.secondsAgo <= 120) {
      return "#3ec898"; // green
    } else {
      return "#f26578"; // red
    }
  };

  const getRCNname = () => {
    return (
      localStorage.getItem("consolebot.email").split("@")[0].toString() +
      "_" +
      item.alias
    );
  };

  const onChange = event => {
    updateItem(item, event.target.checked);
  };

  const openConsole = event => {
    if (event.ctrlKey) {
      event.stopPropagation();
      // const username = localStorage
      //   .getItem("consolebot.email")
      //   .split("@")[0]
      //   .toString();
      window
        .open(`${rcServerUrl}live-stream/${getRCNname()}`, "_blank")
        .focus();

      // window.document.getElementById(username + "_" + item.alias).submit();
    }
  };

  const openConsole2 = event => {
    event.stopPropagation();
    // const username = localStorage
    //   .getItem("consolebot.email")
    //   .split("@")[0]
    //   .toString();
    window.open(`${rcServerUrl}live-stream/${getRCNname()}`, "_blank").focus();
    // window.document.getElementById(username + "_" + item.alias).submit();
  };

  const renderedTime =
    item.secondsAgo > 90
      ? `${Math.round(item.secondsAgo / 60)}m`
      : `${item.secondsAgo}s`;
  return (
    <Label check className="position-relative h-100 w-100">
      <form
        style={{ display: "none" }}
        id={getRCNname()}
        method="POST"
        target="_blank"
      >
        <input type="hidden" name="gameconsole" value={getRCNname()} />
      </form>
      <Input
        type="checkbox"
        onContextMenu={openConsole2}
        onClick={openConsole}
        checked={item.isChecked}
        onChange={onChange}
        className="select-card-checkbox position-absolute m-0"
      />
      <Card
        className="select-status-card shadow-sm h-100 position-relative rounded-sm"
        style={{ backgroundColor: getBackgroundColor() }}
      >
        <div className="cardimage">
          <CardImg
            onContextMenu={openConsole2}
            top
            width="240"
            height="135"
            src={
              item.screen_shot_link
                ? `data:image/webp;base64,${item.screen_shot_link}`
                : GameThumbnail
            }
            alt=""
            className="img-fluid w-100"
          />
          <p className="m-0 font-size-10 imagestatus"></p>
        </div>

        <CardBody className="p-2 position-relative">
          <div className="status-card-body-mask" />
          <div className="w-100 text-truncate m-0 small">
            <span className="font-weight-500">{item.alias}</span>
            {` | ${renderedTime || ""} | ${item.email}`}
          </div>

          <Row style={{ margin: 0 }}>
            <Col md={12} className="p-0">
              <p
                className="m-0 font-size-10 text-truncate pr-1 text-center"
                title={item.status_message}
              >
                {item.status_message}
              </p>
            </Col>
            <Col md={12} className="p-0">
              <CardText className=" font-size-10 start-time text-center">
                {item.description}
              </CardText>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Label>
  );
};
export default StatusCard;
