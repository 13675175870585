import { TOGGLE_LEFT_SIDEBAR } from './HamBurgerMenuActionTypes'

const initialState = {
    isOpenLeftSideBar : false
}


const toggleLeftSideBarReducer = (state=initialState, action) =>{
    switch (action.type) {
        case TOGGLE_LEFT_SIDEBAR:
          return { ...state, isOpenLeftSideBar: action.value }
        default:
          return state
      }
}

export default toggleLeftSideBarReducer