import React from "react";
import { UNPAIRING } from "../../ConfigurationGraphQL";
import { useMutation } from "@apollo/react-hooks";
import RenderDialogBox from "../../../../common/RenderDialogBox";

const PAIRING_TYPE_QUEUE_NAMES = {
  PS4: "PS_pairing",
  PS5: "PS_pairing",
  XCLOUD: "XCLOUD_pairing",
  HW: "HW_pairing"
};

export const UnpairingModal = ({ gridApi, toastMessgage, setActionModal }) => {
  const [unPairing] = useMutation(UNPAIRING);

  const handleClickonYes = async () => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);

    const data = selectedData;
    let emails = [];
    data.forEach(item => {
      const { email, type } = item;
      if (PAIRING_TYPE_QUEUE_NAMES[type]) emails.push({ email, type });
    });
    if (emails.length) {
      const username = localStorage.getItem("consolebot.email");
      const payload = {
        username: username,
        action: "delete",
        accounts: emails
      };

      try {
        await unPairing({ variables: { consoleDetails: payload } });
        toastMessgage("success", "Done");
      } catch (e) {
        toastMessgage("error", e.message);
      }
    } else toastMessgage("error", "Wrong Types!");
    setActionModal({});
  };

  const getMessage = () => {
    let count = 0;
    if (gridApi && gridApi.getSelectedNodes) {
      const selectedNodes = gridApi.getSelectedNodes();
      if (selectedNodes) {
        const selectedData = selectedNodes.map(node => node.data);
        count = selectedData.length;
      }
    }
    return `Are you sure you want to remove pairing for ${count} accounts? \n \
        This action is irreversible!`;
  };
  return (
    <RenderDialogBox
      isOpen={true}
      handleDialogBox={() => setActionModal({})}
      btnOne={"Confirm"}
      btnTwo={"Cancel"}
      message={getMessage()}
      handleClickonYes={handleClickonYes}
    />
  );
};
export default UnpairingModal;
