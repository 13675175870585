import { gql } from "apollo-boost";

export const UPDATECONSOLE = gql`
  mutation updateConsole($data: JSON!) {
    updateConsole(input: $data)
  }
`;

export const UPDATEMULTIPLECONSOLES = gql`
  mutation updateMultipleConsoles($data: JSON!) {
    updateMultipleConsoles(consoles: $data)
  }
`;

export const DELETECONSOLE = gql`
  mutation deleteConsole($id: [Int]!) {
    deleteConsole(id: $id)
  }
`;

export const ACTION = gql`
  mutation action($actionType: String!, $consoleDetails: [ConsoleDetails]!) {
    action(actionType: $actionType, input: $consoleDetails)
  }
`;

export const PAIRING = gql`
  mutation pairing($consoleDetails: [Int]!, $username: String!) {
    pairing(input: $consoleDetails, username: $username)
  }
`;

export const UNPAIRING = gql`
  mutation unPairing($consoleDetails: JSON!) {
    unPairing(input: $consoleDetails)
  }
`;

export const PSNSIGNIN = gql`
  mutation psnSignIn($consoleDetails: [JSON]!) {
    psnSignIn(input: $consoleDetails)
  }
`;

export const PSNREG = gql`
  mutation psnReg($PSNRef: JSON!) {
    psnReg(input: $PSNRef)
  }
`;

export const PSNTASK = gql`
  mutation psnTask($PSNRef: JSON!) {
    psnTask(input: $PSNRef)
  }
`;

export const EAREG = gql`
  mutation eaRegTask($eaList: [String]!) {
    eaRegTask(eaList: $eaList)
  }
`;

export const STEAMREG = gql`
  mutation steamRegTask($steamList: [Int]!) {
    steamRegTask(steamList: $steamList)
  }
`;

export const STARTBROWSER = gql`
  mutation startBrowserTask($emailList: [String]!) {
    startBrowserTask(emailList: $emailList)
  }
`;

export const BANCHECK = gql`
  mutation banCheck($BANRef: [JSON]!) {
    banCheck(input: $BANRef)
  }
`;

export const VALIDATE_LICENSE = gql`
  mutation validateLicense($consoleDetails: [JSON]!) {
    validateLicense(input: $consoleDetails)
  }
`;

export const GETCONSOLEFORADMIN = gql`
  query getConsole {
    getConsole {
      id
      server
      email
      psnid
      ea_password
      alias
      mac_address
      gauth
      ea_proxy
      script
      config
      status
      customer_id
      customer_farm_name
      ea_email
      password
      proxyip
      proxyport
      proxy_login
      proxy_password
      rotation_link
      ps_name
      screen_shot_link
      times_tamp
      pairing_status
      status_message
      psn_token
      rp_regist_key
      rp_key
      psn_signin_status
      accountId
      imap_login
      imap_password
      email_web_password
      imap_host
      imap_gauth
      imap_proxy
      description
      license_expiration
      coins
      sb_points
      reg_status
      firstname
      lastname
      dob
      muled
      console_ip
      console_ip_actual_time
      public_ip
      psn_gauth
      steam_accountname
      steam_keys
      iron_serial
      psn_backups
      hwid
      psn_reg_country
      originId
      ea_securityAnswer
      country
      ea_birthday
      ea_firstname
      ea_lastname
      ea_imapusername
      ea_imappassword
      ea_imaphost
      ea_imapport
      ea_reg_status
      ea_reg_date
      reg_ban
      muling_ban
      botting_ban
      ban_check_status
      type
      avatar
      storage
      ea_backups
      fifa_stats
      args
      tm_unlocked
    }
  }
`;

export const GETOVERVIEW = gql`
  query getOverview {
    getOverview {
      server
      email
      alias
      description
      status
      customer_id
      customer_farm_name
      screen_shot_link
      times_tamp
      status_message
      id
    }
  }
`;

export const MSG_SUBSCRIBE = gql`
  subscription messageCreated($userId: Int!, $useIndex: Int) {
    messageCreated(userId: $userId, useIndex: $useIndex)
  }
`;

export const OVERVIEW_SUBSCRIBE = gql`
  subscription overviewUpdate($userId: Int!) {
    overviewUpdate(userId: $userId)
  }
`;

export const GETCUSTOMERBYID = gql`
  query getCustomerById($id: Int) {
    getCustomerById(id: $id) {
      id
      name
      email
      balance
    }
  }
`;
export const GETADMINSETTING = gql`
  query getAdminSetting {
    getAdminSetting {
      id
      montly_license_price
    }
  }
`;

export const GETCONSOLEFORUSER = gql`
  query getConsole {
    getConsole {
      id
      server
      email
      psnid
      ea_password
      alias
      mac_address
      gauth
      ea_proxy
      script
      config
      status
      customer_id
      customer_farm_name
      ea_email
      password
      proxyip
      proxyport
      proxy_login
      proxy_password
      rotation_link
      ps_name
      screen_shot_link
      times_tamp
      pairing_status
      status_message
      rp_regist_key
      rp_key
      psn_signin_status
      accountId
      imap_login
      imap_password
      email_web_password
      imap_host
      imap_gauth
      imap_proxy
      description
      license_expiration
      coins
      sb_points
      reg_status
      firstname
      lastname
      dob
      muled
      console_ip
      console_ip_actual_time
      public_ip
      psn_gauth
      steam_accountname
      steam_keys
      iron_serial
      psn_backups
      hwid
      psn_reg_country
      originId
      ea_securityAnswer
      country
      ea_birthday
      ea_firstname
      ea_lastname
      ea_imapusername
      ea_imappassword
      ea_imaphost
      ea_imapport
      ea_reg_status
      ea_reg_date
      reg_ban
      muling_ban
      botting_ban
      ban_check_status
      type
      avatar
      storage
      ea_backups
      fifa_stats
      args
      tm_unlocked
    }
  }
`;

export const GETGRIDVIEWFILTER = gql`
  query getGridViewFilter {
    getGridViewFilter
  }
`;
export const ADDGRIDVIEWFILTER = gql`
  mutation addGridViewFilter($consoleDetails: JSON!) {
    addGridViewFilter(input: $consoleDetails)
  }
`;
export const DELETEGRIDVIEWFILTER = gql`
  mutation deleteGridViewFilter($id: Int!) {
    deleteGridViewFilter(id: $id)
  }
`;
