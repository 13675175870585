import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from "react";
import { OVERVIEW_SUBSCRIBE } from "../../../Configuration/ConfigurationGraphQL";
import { useSubscription } from "@apollo/react-hooks";
import { filter, sortBy } from "lodash";
import ItemsGrid from "./componenets/itemsGrid";

const refreshDelay =
  // eslint-disable-next-line no-undef
  parseInt(process.env.REACT_APP_CONSOLE_LIST_REFRESH_DELAY) || 2000;

const inititalStatus = {
  active: 0,
  inactive: 0,
  sleep: 0,
  error: 0,
  farms: {}
};

const getStatus = (console, statuses) => {
  const { status, customer_farm_name, server, secondsAgo } = console;
  if (!statuses.farms[customer_farm_name])
    statuses.farms[customer_farm_name] = { total: 1, inactive: 0 };
  else statuses.farms[customer_farm_name].total++;
  if (!statuses.farms[customer_farm_name][server])
    statuses.farms[customer_farm_name][server] = { total: 1, inactive: 0 };
  else statuses.farms[customer_farm_name][server].total++;
  if (
    status &&
    typeof status === "string" &&
    status.toLowerCase() === "error"
  ) {
    statuses.error++;
  } else if (secondsAgo > 120) {
    statuses.inactive++;
    statuses.farms[customer_farm_name].inactive++;
    statuses.farms[customer_farm_name][server].inactive++;
  } else if (
    status &&
    typeof status === "string" &&
    status.toLowerCase() === "working" &&
    secondsAgo <= 120
  ) {
    statuses.active++;
  } else if (
    status &&
    typeof status === "string" &&
    status.toLowerCase() === "sleep"
  ) {
    statuses.sleep++;
  }
};

const getFarmFilter = customer_farm_name => {
  let farmFilter = null;
  if (customer_farm_name) {
    farmFilter = {};
    customer_farm_name.forEach(farm => {
      farmFilter[farm.name] = {};
      if (farm.server) {
        farmFilter[farm.name].splitToServers = true;
        const farmServers = farm.server.split(",");
        farmServers.forEach(server => {
          farmFilter[farm.name][server] = true;
        });
      }
    });
  }

  return farmFilter;
};

// eslint-disable-next-line complexity
const checkFilters = (
  item,
  farmFilter,
  currentStatusFilter,
  descriptionSearchString
) => {
  let filtered = true;
  if (farmFilter) {
    const farmName = farmFilter[item.customer_farm_name];
    if (
      !farmName ||
      (farmName && farmName.splitToServers && !farmName[item.server])
    ) {
      filtered = false;
    }
  }

  if (
    descriptionSearchString &&
    typeof descriptionSearchString === "string" &&
    (!item.status_message ||
      (typeof item.status_message === "string" &&
        !item.status_message
          .toLowerCase()
          .includes(descriptionSearchString.toLowerCase())))
  ) {
    filtered = false;
  }

  if (currentStatusFilter && filtered) {
    filtered =
      (currentStatusFilter === "error" &&
        item.status &&
        typeof item.status === "string" &&
        item.status.toLowerCase() === "error") ||
      (currentStatusFilter === "inactive" &&
        item.secondsAgo > 120 &&
        typeof item.status === "string" &&
        !(item.status && item.status.toLowerCase() === "error")) ||
      (currentStatusFilter === "active" &&
        item.secondsAgo <= 120 &&
        item.status &&
        typeof item.status === "string" &&
        item.status.toLowerCase() === "working") ||
      (currentStatusFilter === "sleep" &&
        item.status &&
        typeof item.status === "string" &&
        item.status.toLowerCase() === "sleep" &&
        item.secondsAgo <= 120);
  }
  return filtered;
};

const ConsoleList = props => {
  let {
    data,
    customer_farm_name,
    status: currentStatusFilter,
    isSelectAll,
    setStatuses,
    updateSeletectConsole,
    refetchConsole,
    descriptionSearchString
  } = props;

  const transformData = consoleData => {
    if (consoleData) {
      const statuses = { ...inititalStatus, farms: {} };
      const grouped = consoleData.map(el => {
        const secondsAgo = Math.round(
          (Date.now() - new Date(el.times_tamp).getTime()) / 1000
        );
        const newItem = { ...el, secondsAgo };
        getStatus(newItem, statuses);
        return newItem;
      });
      if (consoleData.length) {
        setStatuses(statuses);
      }
      return sortBy(grouped, "alias");
    }
    return [];
  };

  const [consData, setConsoleData] = useState(() => transformData(data));
  const [consDataBackup, setConsoleDataBackup] = useState(() =>
    transformData(data)
  );
  const userId = localStorage.getItem("consolebot.userId");
  const { data: subscribeData } = useSubscription(OVERVIEW_SUBSCRIBE, {
    variables: { userId: parseInt(userId) }
  });

  const incomingCounter = useRef(null);
  const incomingStuff = useRef({});
  const notVisited = useRef(true);

  useEffect(() => {
    if (subscribeData && subscribeData.overviewUpdate && props.data) {
      const { overviewUpdate } = subscribeData;
      overviewUpdate.forEach(item => {
        const { alias } = item;
        incomingStuff.current[alias] = item;
      });

      const time = Date.now();
      if (!incomingCounter.current) incomingCounter.current = time;
      const bufferTimer = notVisited.current ? 300 : refreshDelay;

      if (time - incomingCounter.current > bufferTimer) {
        const statuses = { ...inititalStatus, farms: {} };

        const filteredConsoles = [];
        const farmFilter = getFarmFilter(customer_farm_name);

        const conData = consDataBackup.map(item => {
          let returnedItem;

          const refreshedItem = incomingStuff.current[item.alias];

          if (refreshedItem) {
            const secondsAgo = Math.round(
              (time - new Date(refreshedItem.times_tamp).getTime()) / 1000
            );
            returnedItem = {
              ...item,
              ...refreshedItem,
              secondsAgo
            };
            incomingStuff.current[item.alias] = false;
          } else {
            const secondsAgo = Math.round(
              (time - new Date(item.times_tamp).getTime()) / 1000
            );
            returnedItem = { ...item, secondsAgo };
            // }
          }
          getStatus(returnedItem, statuses);
          let filtered = checkFilters(
            returnedItem,
            farmFilter,
            currentStatusFilter,
            descriptionSearchString
          );
          if (filtered) filteredConsoles.push(returnedItem);
          return returnedItem;
        });
        const newAlias = Object.values(incomingStuff.current).find(
          value => !value
        );
        if (newAlias) {
          refetchConsole();
        }
        setStatuses(statuses);
        setConsoleDataBackup(conData);
        if (currentStatusFilter === "inactive")
          setConsoleData(sortBy(filteredConsoles, "secondsAgo"));
        else setConsoleData(filteredConsoles);
        incomingCounter.current = null;
        incomingStuff.current = {};
      }
    }
    // eslint-disable-next-line
  }, [subscribeData]);

  useEffect(() => {
    const cData = transformData(data, true);
    if (currentStatusFilter) {
      const farmFilter = getFarmFilter(customer_farm_name);
      const filteredData = cData.filter(el => {
        return checkFilters(
          el,
          farmFilter,
          currentStatusFilter,
          descriptionSearchString
        );
      });
      setConsoleData(() => filteredData);
    } else {
      setConsoleData(() => cData);
    }
    setConsoleDataBackup(() => cData);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (
      currentStatusFilter ||
      consDataBackup.length !== consData.length ||
      descriptionSearchString
    ) {
      const farmFilter = getFarmFilter(customer_farm_name);

      setConsoleData(() =>
        consDataBackup.filter(el => {
          return checkFilters(
            el,
            farmFilter,
            currentStatusFilter,
            descriptionSearchString
          );
        })
      );
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [currentStatusFilter, descriptionSearchString]);

  useEffect(() => {
    if (customer_farm_name || consDataBackup.length !== consData.length) {
      const farmFilter = getFarmFilter(customer_farm_name);
      setConsoleData(() =>
        consDataBackup.filter(el => {
          return checkFilters(
            el,
            farmFilter,
            currentStatusFilter,
            descriptionSearchString
          );
        })
      );
    }
    // eslint-disable-next-line
  }, [customer_farm_name]);

  useEffect(() => {
    let newList = consDataBackup.map(item => {
      item.isChecked = isSelectAll;
      return item;
    });
    setConsoleDataBackup(() => newList);
    newList = consData.map(item => {
      item.isChecked = isSelectAll;
      return item;
    });
    setConsoleData(() => newList);
    if (isSelectAll) updateSeletectConsole(newList);
    else updateSeletectConsole([]);
    // eslint-disable-next-line
  }, [isSelectAll]);

  const updateItem = useCallback(
    async (item, isChecked) => {
      const indx = consData.findIndex(
        obj => parseInt(obj.id) === parseInt(item.id)
      );
      let tmp = [...consData];
      tmp[indx].isChecked = isChecked;
      await setConsoleData(() => tmp);

      const objIndex = consDataBackup.findIndex(
        obj => parseInt(obj.id) === parseInt(item.id)
      );
      tmp = [...consDataBackup];
      tmp[objIndex].isChecked = isChecked;
      setConsoleDataBackup(() => tmp);
      const selectedConsole = filter(consData, "isChecked");
      updateSeletectConsole(selectedConsole);
    },
    // eslint-disable-next-line
    [consData]
  );

  const renderedData = useMemo(() => consData, [consData]);
  return <ItemsGrid consData={renderedData} updateItem={updateItem} />;
};
export default ConsoleList;
