import React, { useCallback, useEffect, useState } from "react";
import RenderMessageBox from "../../../../common/RenderMessageBox";
import RenderDialogBox from "../../../../common/RenderDialogBox";
import { VALIDATE_LICENSE, GETADMINSETTING } from "../../ConfigurationGraphQL";
import { useMutation } from "@apollo/react-hooks";
import { useQuery } from "@apollo/react-hooks";
import { filter, uniqBy } from "lodash";
import { ACTION_MODALS } from "./index";

export const LicenseErrorDialog = ({ setActionModal }) => (
  <RenderMessageBox
    isOpen={true}
    btnOne={"OK"}
    message={"There is no MAC address in selected rows"}
    handleClick={() => setActionModal(null)}
  />
);

export const LicenseDialog = ({ setActionModal, toastMessgage, gridApi }) => {
  const { data, refetch } = useQuery(GETADMINSETTING);
  const [validateLicense] = useMutation(VALIDATE_LICENSE);
  const [licenseRow, setLicenseRow] = useState([]);

  const getInitialRows = useCallback(async () => {
    await refetch();
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    const data = selectedData;
    let row = filter([...data], item => {
      delete item.__typename;
      item.id = parseInt(item.id);
      return item.mac_address !== null && item.mac_address !== "";
    });

    row = uniqBy(row, "mac_address");
    if (row.length === 0) {
      setActionModal({ [ACTION_MODALS.LICENSE_ERROR]: true });
    } else {
      setLicenseRow(row);
    }
  }, [gridApi, refetch, setActionModal]);

  useEffect(() => {
    getInitialRows();
  }, [getInitialRows]);

  const getValidateLicense = async () => {
    try {
      const response = await validateLicense({
        variables: { consoleDetails: licenseRow }
      });

      if (response.data.validateLicense.code === 200) {
        toastMessgage("success", response.data.validateLicense.result);
      } else {
        toastMessgage("error", response.data.validateLicense.result);
      }
    } catch (e) {
      toastMessgage("error", e.message);
    }
  };

  const handleClickonYes = async () => {
    getValidateLicense();
    setActionModal({});
  };

  const getMessage = () => {
    // await refetch()
    let mlp = 0;
    if (data) {
      mlp = data.getAdminSetting[0].montly_license_price;
    }
    const conCount = licenseRow.length;
    const amt = conCount * mlp;
    return `Add license to ${conCount} consoles for ${amt}€`;
  };

  return (
    <RenderDialogBox
      isOpen={true}
      handleDialogBox={() => setActionModal({})}
      btnOne={"Confirm"}
      btnTwo={"Cancel"}
      message={getMessage()}
      handleClickonYes={handleClickonYes}
    />
  );
};
