import { gql } from "apollo-boost"

export const GETSTATUS = gql`
query getStatus{
  getFarmList  
}
` 

export const GETCONSOLEONLY = gql`
query getConsoleOnly($field : JSON){
  getConsoleOnly(field :$field){
    id
    email
    server
    alias
    status
    customer_id
    customer_farm_name    
    screen_shot_link
    times_tamp
    description 
    status_message
  }
}
` 

export const GETFARMLIST = gql`
query getFarmList {
  getFarmList
}
`
//  example data
// "getFarmList": [
//   {
//     "customer_farm_name": "Birch Wood Farm",
//     "count": "11"
//   },
//   {
//     "customer_farm_name": "Magnolia Ranch",
//     "count": "8"
//   } 
// ]

export const GETCONSOLESTATUS = gql`
query getConsoleStatus{
  getConsoleStatus
}
`

// example data 

// "getConsoleStatus": [
//   {
//     "status": "working",
//     "count": "19"
//   },
//   {
//     "status": "waiting",
//     "count": "20"
//   },
//   {
//     "status": "inactive",
//     "count": "20"
//   }
// ]
