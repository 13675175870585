import React from "react";
import { Container, Row, Col } from "reactstrap";
const Help = props => {
  return (
    <main role="main" className="content-block__width ml-auto px-0">
      {/* Page detains stripe  */}
      <div className="page-details-stripe">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h1 className="stripe-heading m-0 font-weight-600">Get Help</h1>
            </Col>
            <Col xs="auto"></Col>
          </Row>
        </Container>
      </div>
      {/* .Page detains stripe  */}
      <div className="content-wrapper"></div>
    </main>
  );
};
export default Help;
