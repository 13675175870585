import React from "react";
import { Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  // faPencilAlt,
  faCopy,
  faFileCode
} from "@fortawesome/free-solid-svg-icons";
// import { ExpandedView } from "./ExpandedView";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const SettingsListItem = props => {
  const {
    item,
    setWarningBox,
    setSelectedItem,
    // editScriptItem,
    copyScriptItem,
    setEditedJson
  } = props;
  // const [isExpanded, setExpanded] = useState(false);
  // console.log("=== ==> SettingsListItem ==> isExpanded:", isExpanded);

  const removeItem = event => {
    event.stopPropagation();
    setWarningBox("delete");
    setSelectedItem(item);
  };

  // const editItem = event => {
  //   event.stopPropagation();
  //   editScriptItem(item);
  // };

  const copyItem = event => {
    event.stopPropagation();
    copyScriptItem(item);
  };

  const openJSONEditModal = event => {
    event.stopPropagation();
    setEditedJson(item);
  };

  return (
    <>
      <div className="script-row">
        <Row
          className="py-2 align-items-center"
          // onClick={() => setExpanded(!isExpanded)}
        >
          <Col className="font-weight-500">{item.name}</Col>
          <Col sm="auto">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Popover>
                  <span className="p-2">Duplicate</span>
                </Popover>
              }
            >
              <Button color="link" onClick={event => copyItem(event)}>
                <FontAwesomeIcon icon={faCopy} className="fa-fw" />
              </Button>
            </OverlayTrigger>
          </Col>
          <Col sm="auto">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Popover>
                  <span className="p-2">Edit Settings JSON</span>
                </Popover>
              }
            >
              <Button color="link" onClick={event => openJSONEditModal(event)}>
                <FontAwesomeIcon
                  icon={faFileCode}
                  className="fa-fw text-danger"
                />
              </Button>
            </OverlayTrigger>
          </Col>
          {/* <Col sm="auto">
            <OverlayTrigger
              trigger="hover"
              placement="top"
              overlay={
                <Popover>
                  <span className="p-2">Edit Legacy Settings </span>
                </Popover>
              }
            >
              <Button color="link" onClick={event => editItem(event)}>
                <FontAwesomeIcon icon={faPencilAlt} className="fa-fw" />
              </Button>
            </OverlayTrigger>
          </Col> */}
          <Col sm="auto">
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Popover>
                  <span className="p-2">Remove Config</span>
                </Popover>
              }
            >
              <Button color="link" onClick={event => removeItem(event)}>
                <FontAwesomeIcon icon={faTrash} className="fa-fw text-danger" />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </div>
      {/* {isExpanded ? <ExpandedView item={item} /> : null} */}
    </>
  );
};
