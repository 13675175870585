import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import PanelContent from "./PanelContent";
import Animate from "rc-animate";
import shallowEqual from "shallowequal";

class CollapsePanel extends Component {
  shouldComponentUpdate(nextProps) {
    return !shallowEqual(this.props, nextProps);
  }

  handleItemClick = event => {
    event.stopPropagation();
    const { onItemClick, panelKey } = this.props;

    if (typeof onItemClick === "function") {
      onItemClick(panelKey);
    }
  };

  handleKeyPress = e => {
    if (e.key === "Enter" || e.keyCode === 13 || e.which === 13) {
      this.handleItemClick();
    }
  };

  renderHeader = () => {
    const {
      prefixCls,
      header,
      headerClass,
      isActive,
      showArrow,
      disabled,
      accordion,
      expandIcon,
      extra,
      headerOnClick
    } = this.props;

    if (accordion === false) {
      let icon = (
        <i onClick={this.handleItemClick} style={{ marginRight: 20 }}>
          {isActive ? (
            <i className="fa fa-minus" aria-hidden="true"></i>
          ) : (
            <i className="fa fa-plus" aria-hidden="true"></i>
          )}
        </i>
      );
      if (showArrow && typeof expandIcon === "function") {
        icon = expandIcon(this.props);
      }
      const headerCls = classNames(`${prefixCls}-header`, {
        [headerClass]: headerClass
      });
      return (
        <div
          className={headerCls}
          onClick={headerOnClick}
          // role={accordion ? 'tab' : 'button'}
          // tabIndex={disabled ? -1 : 0}
          aria-expanded={isActive}
          // eslint-disable-next-line
          // onKeyPress={this.handleKeyPress}
        >
          {showArrow && icon}
          {header}
          {extra && <div className={`${prefixCls}-extra`}>{extra}</div>}
        </div>
      );
    }

    const headerCls = classNames(`${prefixCls}-header`, {
      [headerClass]: headerClass
    });
    // const itemCls = classNames({
    //   [`${prefixCls}-item`]: true,
    //   [`${prefixCls}-item-active`]: isActive,
    //   [`${prefixCls}-item-disabled`]: disabled,
    // }, className);

    let icon = <i className="arrow" />;
    if (showArrow && typeof expandIcon === "function") {
      icon = expandIcon(this.props);
    }

    return (
      <div
        className={headerCls}
        onClick={this.handleItemClick}
        role={accordion ? "tab" : "button"}
        tabIndex={disabled ? -1 : 0}
        aria-expanded={isActive}
        onKeyPress={this.handleKeyPress}
      >
        {showArrow && icon}
        {header}
        {extra && <div className={`${prefixCls}-extra`}>{extra}</div>}
      </div>
    );
  };

  render() {
    const {
      className,
      id,
      style,
      prefixCls,
      children,
      isActive,
      destroyInactivePanel,
      disabled,
      accordion,
      forceRender
    } = this.props;

    const itemCls = classNames(
      {
        [`${prefixCls}-item`]: true,
        [`${prefixCls}-item-active`]: isActive,
        [`${prefixCls}-item-disabled`]: disabled
      },
      className
    );

    return (
      <div className={itemCls} style={style} id={id}>
        {this.renderHeader()}

        <Animate
          showProp="isActive"
          exclusive
          component=""
          animation={this.props.openAnimation}
        >
          <PanelContent
            prefixCls={prefixCls}
            isActive={isActive}
            destroyInactivePanel={destroyInactivePanel}
            forceRender={forceRender}
            role={accordion ? "tabpanel" : null}
          >
            {children}
          </PanelContent>
        </Animate>
      </div>
    );
  }
}

CollapsePanel.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  id: PropTypes.string,
  children: PropTypes.any,
  openAnimation: PropTypes.object,
  prefixCls: PropTypes.string,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node
  ]),
  headerClass: PropTypes.string,
  showArrow: PropTypes.bool,
  isActive: PropTypes.bool,
  onItemClick: PropTypes.func,
  style: PropTypes.object,
  destroyInactivePanel: PropTypes.bool,
  disabled: PropTypes.bool,
  accordion: PropTypes.bool,
  forceRender: PropTypes.bool,
  expandIcon: PropTypes.func,
  extra: PropTypes.node,
  panelKey: PropTypes.any
};

CollapsePanel.defaultProps = {
  showArrow: true,
  isActive: false,
  destroyInactivePanel: false,
  onItemClick() {},
  headerClass: "",
  forceRender: false
};

export default CollapsePanel;
