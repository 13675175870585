import CryptoJS from "crypto-js";

const keySize = 256;
const iterations = 100;

export const filterPairData = row => {
  return row;
};

export const encryption = msg => {
  const salt = CryptoJS.lib.WordArray.random(128 / 8);

  const key = CryptoJS.PBKDF2("consolebot", salt, {
    keySize: keySize / 32,
    iterations: iterations
  });

  const iv = CryptoJS.lib.WordArray.random(128 / 8);

  const encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: iv,
    padding: CryptoJS.pad.Pkcs7,
    mode: CryptoJS.mode.CBC
  });

  // salt, iv will be hex 32 in length
  // append them to the ciphertext for use  in decryption
  const transitmessage = salt.toString() + iv.toString() + encrypted.toString();
  return transitmessage;
};

export const numberComparator = (a, b) => {
  if (a && !b) return 1;
  if (!a && b) return -1;
  if ((!a && !b) || Number(a) === Number(b)) return 0;
  return Number(a) > Number(b) ? 1 : -1;
};

export const cleanGridExportRow = row => {
  if (row) {
    const exported = {};
    Object.keys(row).forEach(key => {
      if (key === "id") exported.id = parseInt(row.id);
      else
        exported[key] =
          row[key] === null
            ? null
            : (row[key] + "").trim().replace(/\t|\n|\r/g, "");
    });
    return exported;
  } else return row;
};
