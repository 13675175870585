import React from "react";
import { Switch, Route } from "react-router-dom";
// import Dashboard from "../pages/Dashboard"
import Status from "../pages/Status";
import Configuration from "../pages/Configuration";
// import Global from "../pages/Settings/Global";
import SettingsPage from "../pages/Settings/SettingsPage";
// import Script from "../pages/Settings/Script";
import Help from "../pages/Help";
import Header from "../common/Header";
import Settings from "../pages/Settings";
import { useSelector } from "react-redux";
import withAuth from "../hoc/withAuth";

const AppContainer = () => {
  // const [ isOpenLeftSideBar] = useState();
  const { isOpenLeftSideBar } = useSelector(
    state => state.toggleLeftSideBarReducer
  );
  const isActiveLeftBar = isOpenLeftSideBar ? "isActiveLeftBar" : "";
  const sidebarOpen = isOpenLeftSideBar ? "side-opened" : "";
  //render() {
  return (
    <section>
      <section className={`app-wrapper position-relative ${isActiveLeftBar}`}>
        <Header />
        <Settings />
        <div className={`${sidebarOpen}`}>
          <Switch>
            <Route exact path="/" component={Status} />
            {/* <Route path="/dashboard" component={Dashboard} /> */}
            <Route path="/overview" component={Status} />
            <Route path="/configuration" component={Configuration} />
            <Route path="/settings" component={SettingsPage} />
            {/* <Route path="/config" component={Script} /> */}
            {/* <Route path="/addscript" component={ScriptForm} /> */}
            <Route path="/help" component={Help} />
          </Switch>
        </div>
      </section>
    </section>
  );
};
//}
export default withAuth(AppContainer);
