export const aeReg = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { eaRegTask }
  } = props;

  const selectedNodes = gridApi.getSelectedNodes();
  let regList = [];
  selectedNodes.forEach(node => {
    // let isFieldMissing = false;
    // let status = "Fields missing : [";
    // if (!node.data.ea_email) {
    //   status += " email, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_password) {
    //   status += " password, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.originId) {
    //   status += " origin ID, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_securityAnswer) {
    //   status += " security answer, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.country) {
    //   status += " country, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_birthday) {
    //   status += " birthday, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_firstname) {
    //   status += " first name, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_lastname) {
    //   status += " last name, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_imapusername) {
    //   status += " IMAP user, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_imappassword) {
    //   status += " IMAP password, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_imaphost) {
    //   status += " IMAP host, ";
    //   isFieldMissing = true;
    // }
    // if (!node.data.ea_imapport) {
    //   status += " IMAP port, ";
    //   isFieldMissing = true;
    // }

    // status += "]";
    // if (isFieldMissing) {
    //   node.setDataValue("ea_reg_status", status);
    // } else {
    regList.push(node.data.email);
    // }
  });

  try {
    if (regList.length > 0) {
      const result = await eaRegTask({ variables: { eaList: regList } });
      const response = result?.data?.eaRegTask ?? null;
      if (response) {
        if (response && response.statuses) {
          Object.values(response.statuses).forEach(el => {
            if (el) toastMessgage(el.status, el.message);
          });
        } else if (response.status) {
          toastMessgage(response.status, response.message);
        }
      } else {
        toastMessgage("error", "No response");
      }
    } else {
      toastMessgage("info", "There is no record for Registration");
    }
  } catch (e) {
    toastMessgage("error", e.message);
  }
};

export default aeReg;
