import React from "react";
import { Container, Row, Col } from "reactstrap";
import TwoCaptchKey from "./components/TwoCaptchKey";
const Global = () => {
  return (
    <>
      <div className="page-details-substripe">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <h2 className="stripe-heading m-0 font-weight-500">Global</h2>
            </Col>
            <Col xs="auto">
              <small className="text-muted"></small>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="content-wrapper bg-light">
        <Container fluid={true}>
          <Row className="justify-content-between align-items-center">
            <Col>
              <div>
                <TwoCaptchKey />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Global;
