import * as actions from "./SettingsTypes"

export const initialState = {
  setting : {}
}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) { 
    case actions.CAPTCHAKAY:
      const setting = action.payload
      return { ...state, setting }
    case actions.REMOVECAPTCHA:
        return { ...state, setting : {} }
    default:
      return state
  }
}

export default settingsReducer
