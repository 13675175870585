import React from "react"
import { useQuery } from '@apollo/react-hooks';
import { GETSETTINGS } from "./SettingsGraphQL";
import { useDispatch } from 'react-redux'
import { addCaptchaKeyinStore } from "./SettingsActions";


const Settings = () => {
  const { data } = useQuery(GETSETTINGS, { fetchPolicy: "network-only" }); 
  const dispatch = useDispatch(); 
  if (data && data.getSettings) {
    dispatch(addCaptchaKeyinStore(data.getSettings))
  }

  return (
    <div></div>
  )
}
export default Settings
