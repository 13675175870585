import React from "react";
import jwtDecode from "jwt-decode";
import { Redirect } from "react-router-dom";

const verifyToken = () => {
  const authToken = localStorage.getItem("consolebot.token");
  let decoded;
  try {
    decoded = jwtDecode(authToken);
  } catch (err) {
    return false;
  }
  const current_time = Date.now().valueOf() / 1000;
  if (decoded.exp < current_time) {
    localStorage.clear();
    return false;
  }
  return authToken;
};

const withAuth = Component => props => {
  return verifyToken() ? <Component {...props} /> : <Redirect to="/login" />;
};

export default withAuth;
