import React, { useState, useEffect } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import "string.prototype.repeat";
import Collapse, { Panel } from "../../../../common/Collapse";
import { groupBy } from "lodash";
import { GETSTATUS } from "../../StatusGraphQL";
import { useQuery } from "@apollo/react-hooks";

const FarmList = props => {
  const [activeKey, setActiveKey] = useState(() => []);
  const { fetchRecord, statuses } = props;
  const [farmServerList, setFarmServerList] = useState(() => []);
  const { data } = useQuery(GETSTATUS, { fetchPolicy: "network-only" }); //, {fetchPolicy: "network-only"}
  useEffect(() => {
    if (data) {
      const farmNameList = Object.keys(data.getFarmList);
      const farmSerLst = [];
      farmNameList.forEach(farmName => {
        const farmObj = { name: farmName, isSelected: false, serverList: [] };
        const serverList = groupBy(data.getFarmList[farmName], "server");
        const selectNameList = Object.keys(serverList);
        selectNameList.forEach(serverName => {
          const serverObj = { name: serverName, isSelected: false };
          farmObj.serverList.push(serverObj);
        });
        farmSerLst.push(farmObj);
      });
      setFarmServerList(() => farmSerLst);
    }
  }, [data]);

  const onChange = activeKey => {
    setActiveKey(activeKey);
  };

  const updateFilter = () => {
    let filterObj = { customer_farm_name: [] };
    farmServerList.map(item => {
      let serverList = [];
      item.serverList.map(server => {
        if (server.isSelected) {
          serverList.push(server.name);
        }
        return true;
      });
      if (serverList.length > 0) {
        filterObj.customer_farm_name.push({
          name: item.name,
          server: serverList.toString()
        });
      } else {
        if (item.isSelected) {
          filterObj.customer_farm_name.push({ name: item.name });
        }
      }
      return true;
    });

    fetchRecord(filterObj);
  };

  const headerOnClick = async (farm, index) => {
    let fsl = [...farmServerList];
    fsl[index].isSelected = !fsl[index].isSelected;
    await setFarmServerList(() => fsl);
    updateFilter();
  };

  const serverOnClick = async (item, index, serverIndex) => {
    let fsl = [...farmServerList];
    let serverList = fsl[serverIndex].serverList;
    serverList[index].isSelected = !serverList[index].isSelected;
    fsl[serverIndex].serverList = serverList;
    await setFarmServerList(() => fsl);
    updateFilter();
  };

  const getItems = () => {
    const items = [];
    farmServerList.map((farm, index) => {
      const { name, isSelected } = farm;
      const currentStatus = statuses?.[name];
      items.push(
        <Panel
          key={"panel" + index}
          headerOnClick={() => headerOnClick(farm, index)}
          header={
            currentStatus ? (
              <span>
                {name + " "}
                <span className="text-danger">
                  {currentStatus.inactive + " "}
                </span>
                ({currentStatus.total})
              </span>
            ) : (
              name
            )
          }
          className={isSelected ? "group_sidebar" : ""}
        >
          <NavItem>
            {getServerName(farm.serverList, index, currentStatus)}
          </NavItem>
        </Panel>
      );
      return farm;
    });
    return items;
  };

  const getServerName = (list, serverIndex, currentStatus) => {
    return list.map((item, index) => {
      return (
        <NavLink
          key={"navlink" + index}
          onClick={() => serverOnClick(item, index, serverIndex)}
          className={`${
            item.isSelected ? "active" : ""
          } d-flex align-items-center justify-content-between farmlist `}
        >
          {currentStatus && currentStatus[item.name] ? (
            <span>
              {item.name + " "}
              <span className="text-danger">
                {currentStatus[item.name].inactive + " "}
              </span>
              ({currentStatus[item.name].total})
            </span>
          ) : (
            item.name
          )}
        </NavLink>
      );
    });
  };

  const generateAccordion = () => {
    return (
      <Collapse accordion={false} onChange={onChange} activeKey={activeKey}>
        {getItems()}
      </Collapse>
    );
  };
  return (
    <>
      <Nav vertical>{generateAccordion()}</Nav>
    </>
  );
};
export default FarmList;
