import React from "react";
import {
  Button,
  Form,
  Label,
  Input,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";

const SaveFilterModal = props => {
  const { addFilter, saveLoading, toggle } = props;
  return (
    <Modal isOpen centered={true} zIndex={2} tabIndex="4" role="dialog">
      <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">
        Save Filter
      </ModalHeader>
      <ModalBody>
        <div>
          <Formik
            initialValues={{ filterName: "" }}
            onSubmit={async values => {
              if (!saveLoading) addFilter(values);
            }}
            validationSchema={Yup.object().shape({
              filterName: Yup.string().required("Required")
            })}
          >
            {props => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit
              } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  {/* <label htmlFor="filterName" style={{ display: "block" }}>Filter Name</label> */}
                  <Label for="filterName">Filter Name</Label>
                  <div className="filter_input">
                    <Input
                      id="filterName"
                      placeholder="Filter set label"
                      type="text"
                      value={values.filterName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className={
                        errors.filterName && touched.filterName
                          ? "text-input error"
                          : "text-input"
                      }
                    />

                    <Button
                      color="primary"
                      size="sm"
                      type="submit"
                      disabled={isSubmitting || saveLoading}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {saveLoading && <Spinner color="light" size="sm" />}
                        <div>Submit</div>
                      </div>
                    </Button>
                    <Button color="promary" size="sm" onClick={() => toggle()}>
                      <div>Cancel</div>
                    </Button>
                  </div>
                  {errors.filterName && touched.filterName && (
                    <div className="input-feedback">{errors.filterName}</div>
                  )}
                </Form>
              );
            }}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SaveFilterModal;
