const psnRequest = async (list, action, toastMessgage, psnTask) => {
  try {
    const result = await psnTask({
      variables: {
        PSNRef: {
          list,
          action
        }
      }
    });
    const response = result?.data?.psnTask ?? null;
    if (response) {
      toastMessgage(response.status, response.message);
    } else {
      toastMessgage("error", "No response");
    }
  } catch (e) {
    toastMessgage("error", e.message);
  }
};

const psnReg = props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { psnTask }
  } = props;

  const selectedNodes = gridApi.getSelectedNodes();
  const regList = [];
  selectedNodes.forEach(node => {
    let isFieldMissing = false;
    let status = "Fields missing : [";
    if (!node.data.dob) {
      status += " birthday, ";
      isFieldMissing = true;
    }
    if (!node.data.firstname) {
      status += " First Name, ";
      isFieldMissing = true;
    }
    if (!node.data.lastname) {
      status += " Second Name ";
      isFieldMissing = true;
    }
    status += "]";
    if (isFieldMissing) {
      node.setDataValue("reg_status", status);
    } else {
      regList.push(node.data.id);
    }
  });
  if (regList.length) {
    psnRequest(regList, "reg", toastMessgage, psnTask);
  } else {
    toastMessgage("info", "There is no record for Registration");
  }
};

const psnSignIn = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { psnTask }
  } = props;
  const selectedNodes = gridApi.getSelectedNodes();
  const data = selectedNodes.map(node => node.data);
  const psnSignInList = data.filter(
    item => item.psn_token !== null || item.psn_token !== ""
  );
  if (psnSignInList.length) {
    psnRequest(
      psnSignInList.map(el => el.id),
      "signin",
      toastMessgage,
      psnTask
    );
  } else {
    toastMessgage("info", "There is no records for SignIn");
  }
};

const psnGauth = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { psnTask }
  } = props;
  const selectedNodes = gridApi.getSelectedNodes();
  const data = selectedNodes.map(node => node.data);
  const psnSignInList = data.filter(
    item => item.psn_token !== null || item.psn_token !== ""
  );
  if (psnSignInList.length) {
    psnRequest(
      psnSignInList.map(el => el.id),
      "gauth",
      toastMessgage,
      psnTask
    );
  } else {
    toastMessgage("info", "There is no records for Gauth");
  }
};

export { psnReg, psnSignIn, psnGauth };
