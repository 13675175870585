export const StartStop = async props => {
  let {
    gridApi,
    actionType,
    toastMessgage,
    mutations: { action }
  } = props;
  try {
    const selectedNodes = gridApi.getSelectedNodes();
    const data = selectedNodes.map(node => node.data);
    const consoleDetails = { ids: data.map(item => parseInt(item.id)) };
    await action({ variables: { actionType, consoleDetails } });
    toastMessgage("success", "Done");
  } catch (e) {
    toastMessgage("error", e.message);
  }
};

export default StartStop;
