import { useMemo } from "react";
import { useSubscription } from "@apollo/react-hooks";
import { MSG_SUBSCRIBE } from "../ConfigurationGraphQL";

const ApiClient = ({ gridApi, subsciptionIndex }) => {
  const userId = useMemo(() => localStorage.getItem("consolebot.userId"), []);

  useSubscription(MSG_SUBSCRIBE, {
    variables: { userId: parseInt(userId), useIndex: subsciptionIndex },
    onSubscriptionData: ({
      subscriptionData: {
        data: { messageCreated }
      }
    }) => {
      if (gridApi.applyTransactionAsync) {
        gridApi.applyTransactionAsync({ update: messageCreated });
      }
    }
  });
  return null;
};
export default ApiClient;
