export const pairManually = async data => {
  if (data) {
    try {
      const authtoken = window?.location?.pathname?.includes("admin")
        ? localStorage.getItem("consolebot-admin.token")
        : localStorage.getItem("consolebot.token");
      if (!authtoken) throw new Error("No Token!");
      const { email, console_ip, accountId, pairing_status } = data;
      await fetch("http://localhost:9999/", {
        method: "POST",
        body: `${email} ${console_ip} ${accountId} ${pairing_status} ${authtoken}`
      });
    } catch (error) {
      console.error("pairManually ERROR: ", error);
    }
  } else {
    console.error("pairManually ERROR: ", "No Data");
  }
};
