import React from "react"
import { Spinner } from 'reactstrap';

const Loader = () => {
    return (
        <div style={{ top: "50%", position: "absolute", left: "50%" }}>
            <Spinner animation="grow" variant="primary" />
        </div>
    )
}
//}
export default Loader
