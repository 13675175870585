export const steamReg = async props => {
  const {
    gridApi,
    toastMessgage,
    mutations: { startBrowserTask }
  } = props;

  const selectedNodes = gridApi.getSelectedNodes();
  let regList = [];
  selectedNodes.forEach(node => {
    let isFieldMissing = false;
    let status = "Fields missing : [";
    if (!node.data.email) {
      status += " email, ";
      isFieldMissing = true;
    }

    status += "]";
    if (isFieldMissing) {
      node.setDataValue("reg_status", status);
    } else {
      regList.push(node.data.email);
    }
  });

  try {
    if (regList.length > 0) {
      const result = await startBrowserTask({
        variables: { emailList: regList }
      });
      const response = result?.data?.startBrowserTask ?? null;
      if (response) {
        if (response && response.statuses) {
          Object.values(response.statuses).forEach(el => {
            if (el) toastMessgage(el.status, el.message);
          });
        } else if (response.status) {
          toastMessgage(response.status, response.message);
        }
      } else {
        toastMessgage("error", "No response");
      }
    } else {
      toastMessgage("info", "There is no record for Starting Browser");
    }
  } catch (e) {
    toastMessgage("error", e.message);
  }
};

export default steamReg;
