import React, { useState } from "react";
import { Nav, NavItem, NavLink, Input } from "reactstrap";
import { debounce } from "throttle-debounce";

const startDescriptionFilter = debounce(350, (string, func) => {
  func(string);
});

const ConsoleStatus = props => {
  const [active, setActiveStatus] = useState("");
  const [descriptionFilterString, setDescriptionFilterString] = useState("");
  const { fetchRecord, statuses } = props;

  const filterConsole = async status => {
    const newStatus = active === status ? "" : status;
    fetchRecord(newStatus);
    await setActiveStatus(() => newStatus);
  };

  const changeDescriptionSearchHandler = e => {
    const { value } = e.target;
    setDescriptionFilterString(value);
    startDescriptionFilter(value, props.changeDescriptionSearchString);
  };

  return (
    <>
      <Nav vertical>
        <NavItem>
          <NavLink
            onClick={() => filterConsole("active")}
            className={`${
              active === "active" ? "active" : ""
            } d-flex align-items-center justify-content-between`}
          >
            <span>Active</span>
            <div className="pl-2">{statuses.active || 0}</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => filterConsole("inactive")}
            className={`${
              active === "inactive" ? "active" : ""
            } d-flex align-items-center justify-content-between`}
          >
            <span>Inactive</span>
            <div className="pl-2">{statuses.inactive || 0}</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => filterConsole("sleep")}
            className={`${
              active === "sleep" ? "active" : ""
            } d-flex align-items-center justify-content-between`}
          >
            <span>Sleep</span>
            <div className="pl-2">{statuses.sleep || 0}</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            onClick={() => filterConsole("error")}
            className={`${
              active === "error" ? "active" : ""
            } d-flex align-items-center justify-content-between`}
          >
            <span>Error</span>
            <div className="pl-2">{statuses.error || 0}</div>
          </NavLink>
        </NavItem>
        <NavItem>
          <Input
            placeholder="Filter by Description..."
            type="text"
            className="side-status-filter-input mb-2"
            value={descriptionFilterString}
            onChange={changeDescriptionSearchHandler}
          />
        </NavItem>
      </Nav>
    </>
  );
};
export default ConsoleStatus;
