import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import Dropzone from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import { useMutation } from "@apollo/react-hooks";
import { UPLOADFILE } from "./uploadGraphQL";
import { adminColumnDefs } from "../../pages/tableConfig";

const getConfigTableFields = () => {
  const fields = {};

  adminColumnDefs.forEach(column => {
    if (!column.children) fields[column.headerName] = column.field;
    else {
      column.children.forEach(
        subColumn =>
          (fields[`${column.headerName}_${subColumn.headerName}`] =
            subColumn.field)
      );
    }
  });
  return fields;
};

const UploadCSV = props => {
  const { className, setActionModal, fetchRecord } = props;

  const [singleUpload] = useMutation(UPLOADFILE);
  const { addToast } = useToasts();

  const [isLoading, setUploading] = useState(false);
  const [selectedFile, setFile] = useState(undefined);
  const handleFileUpload = files => {
    const file = files[0];
    setFile(file);
  };

  const uploadFile = async () => {
    setUploading(true);
    if (selectedFile) {
      if (selectedFile.size) {
        try {
          const fields = getConfigTableFields();
          const response = await singleUpload({
            variables: { file: selectedFile, fields }
          });
          addToast(response.data.singleUpload.success, {
            appearance: "success",
            autoDismiss: true
          });
          setUploading(false);
          setFile(undefined);
          setActionModal({});
          fetchRecord();
        } catch (error) {
          setUploading(false);
          addToast(error.message, {
            appearance: "error",
            autoDismiss: true
          });
        }
      }
    } else {
      setUploading(false);
      addToast("This file type is not supported.", {
        appearance: "error",
        autoDismiss: true
      });
    }
  };
  const dropzoneMessage = () => {
    if (selectedFile) {
      return selectedFile.name;
    }
    return "Drop your file here";
  };
  // const accept =  "text/csv"
  return (
    <>
      <Modal
        isOpen={true}
        centered={true}
        zIndex={999999999}
        className={className}
        tabIndex="4"
        role="dialog"
      >
        <ModalHeader tag="h6" className="py-2 text-dark bg-light shadow-sm">
          Upload CSV File
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="exampleFile">Upload CSV</Label>
              <Dropzone accept={".csv"} onDrop={handleFileUpload}>
                {({ getRootProps, getInputProps }) => {
                  return (
                    <div {...getRootProps()} className={"file-upload"}>
                      <div
                        className={`drop-box d-flex align-items-center justify-content-center  " Additional-photos" : ""}`}
                        style={{
                          height: 130,
                          borderRadius: "8px",
                          cursor: "default"
                        }}
                      >
                        {" "}
                        {dropzoneMessage()}
                      </div>
                      <input {...getInputProps()} />
                    </div>
                  );
                }}
              </Dropzone>
              {/* <Input type="file" name="file" id="exampleFile" /> */}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="d-block">
          <Row className="justify-content-center">
            <Col xs="7" className="pr-0">
              {isLoading ? (
                <Button color="primary" block>
                  <Spinner size="sm" color="danger" />
                </Button>
              ) : (
                <Button
                  color="primary"
                  block
                  onClick={uploadFile}
                  disabled={!selectedFile}
                >
                  Submit
                </Button>
              )}
            </Col>
            <Col>
              <Button
                color="secondary"
                block
                onClick={() => setActionModal({})}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UploadCSV;
