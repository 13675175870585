import { HttpLink, InMemoryCache, ApolloClient } from "apollo-client-preset";
import { ApolloLink, split } from "apollo-link";
import { createUploadLink } from "apollo-upload-client";
// import { setContext } from 'apollo-link-context';
import { WebSocketLink } from "apollo-link-ws";
import { getMainDefinition } from "apollo-utilities";
import configurationReducer from "../pages/Configuration/ConfigurationReducers";
import settingsReducer from "../pages/Settings/SettingsReducers";
import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import reduxStorage from "./storage";
import thunk from "redux-thunk";
import { onError } from "apollo-link-error";
import toggleLeftSideBarReducer from "../common/HamBurgerMenu/HamBurgerMenuReducers";
// let uri = "http://consolebot-api.47billion.com/graphql"

console.log("**************************************************");
// eslint-disable-next-line
console.log(process.env);
console.log("**************************************************");
// let repeat = 0
// eslint-disable-next-line
let uri = `${process.env.REACT_APP_API_PREFIX}${process.env.REACT_APP_GRAPHQL_URI}`;
// eslint-disable-next-line
let wsUrl = `${process.env.REACT_APP_WS_PREFIX}${process.env.REACT_APP_GRAPHQL_URI}`;
// eslint-disable-next-line
if (process.env.NODE_ENV === "production") {
  //uri = "http://api.console-bot.com/graphql"
  //wsUrl = 'ws://api.console-bot.com/graphql'
  // uri = "http://consolebot-api.47billion.com/graphql"
  // wsUrl = 'ws://consolebot-api.47billion.com/graphql'
}

// uri = "http://api.console-bot.com/graphql"
// wsUrl = 'ws://api.console-bot.com/graphql'
// wsUrl = 'ws://api.console-bot.com/graphql'

const wsLink = new WebSocketLink({
  uri: wsUrl,
  options: {
    reconnect: true
  }
});

const uploadLink = createUploadLink({ uri });
const httpLink = new HttpLink({ uri });
const cache = new InMemoryCache();

// const authLink = setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = localStorage.getItem('consolebot.token') || ''
//   const userid = localStorage.getItem('consolebot.userId') || ''
//   const email = localStorage.getItem('consolebot.email') || ''
//   const key = localStorage.getItem('consolebot.key') || ''
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token,
//       userid,
//       email,
//       key
//     }
//   }
// });

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const isAdmin = window.location.pathname.includes("admin");
  let token = localStorage.getItem("consolebot.token") || "";
  let userid = localStorage.getItem("consolebot.userId") || "";
  let email = localStorage.getItem("consolebot.email") || "";
  if (isAdmin) {
    token = localStorage.getItem("consolebot-admin.token") || "";
    userid = localStorage.getItem("consolebot-admin.userId") || "";
    email = localStorage.getItem("consolebot-admin.email") || "";
  }

  // const key = localStorage.getItem('consolebot.key') || ''
  if (!token && !window.location.pathname.includes("login")) {
    logoutUser();
  }
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token,
      userid,
      email
      // key
    }
  }));

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    console.error("graphQLErrors  ", graphQLErrors);
    graphQLErrors.forEach(({ message }) => {
      if (message.includes("Auth token is expired")) {
        logoutUser();
      }
    });
  }
});

// apollo client setup
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  // ApolloLink.from([uploadLink, errorLink, httpLink, authMiddleware])
  ApolloLink.from([errorLink, authMiddleware, uploadLink, httpLink]).concat(
    uploadLink
  )
);

export const client = new ApolloClient({
  cache,
  link
});

const rootPersistConfig = {
  storage: reduxStorage,
  key: "root",
  timeout: null,
  whitelist: ["configurationReducer", "settingsReducer"]
};

const configurationPreferencesConfig = {
  storage: reduxStorage,
  key: "configuration",
  whitelist: ["configurationReducer"]
};

// const settingsPreferencesConfig = {
//   storage: reduxStorage,
//   key: "settings",
//   whitelist: ["settingsReducer"]
// }

let rootReducer = combineReducers({
  configurationReducer: persistReducer(
    configurationPreferencesConfig,
    configurationReducer
  ),
  settingsReducer,
  toggleLeftSideBarReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistReducer(rootPersistConfig, rootReducer),
  composeEnhancer(applyMiddleware(thunk))
);

// export const store = createStore(
//   configurationReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// )

export const persistingStore = persistStore(store);

export default store;

const logoutUser = () => {
  // const isLoggedOut = localStorage.getItem("logout")
  // localStorage.removeItem("Visp.token");
  // apolloStorage.removeItem("apollo-cache-persist");
  persistingStore.purge();
  localStorage.clear();
  client.resetStore();
  window.location.href = "/login";
  window.location.reload(true);
};
